<template>
  <div>
    <HeaderCard :title="title">
      <template v-if="currentPageScope === 'services'" slot="actions">
        <a-button type="primary" icon="plus" @click="handleNewService">
          New Service
        </a-button>
      </template>
    </HeaderCard>
    <component
      :is="Tabs.services.component"
      v-if="!showTabs"
      :searchTerm="findTerm"
    />
    <a-tabs
      v-else
      class="main-tabs"
      :activeKey="activeTab"
      :tabBarStyle="{ margin: 0, paddingLeft: '10px' }"
      size="large"
      :animated="false"
      @change="handleTabChange"
    >
      <a-tab-pane v-for="tab in tabsToShow" :key="tab.key" class="tab-contents">
        <span slot="tab">
          <component :is="tab.iconComponent" :type="tab.iconType" />
          {{ tab.label }}
          <CounterBadge :count="tab.count" />
        </span>
        <component :is="tab.component" :searchTerm="findTerm" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import HeaderCard from "@/components/HeaderCard.vue";
import search from "@/modules/search/index.js";
import levels from "@/modules/levels/index.js";
import CounterBadge from "@/components/CounterBadge.vue";
import { mapActions, mapState } from "vuex";
import pluralize from "pluralize";
import { featureFlags } from "@/mixins/featureFlags.js";
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import { Icon } from "ant-design-vue";

import SearchTabDomains from "./tabs/SearchTabDomains.vue";
import SearchTabSystems from "./tabs/SearchTabSystems.vue";
import SearchTabServices from "./tabs/SearchTabServices.vue";
import SearchTabTechDocs from "./tabs/SearchTabTechDocs.vue";

export const Tabs = {
  services: {
    component: SearchTabServices,
    countVar: "filteredServiceSearchCount",
    iconComponent: OpsIcon,
    iconType: "services",
    key: "services",
    label: "Services",
    elasticsearchRequired: false,
  },
  systems: {
    component: SearchTabSystems,
    countVar: "filteredSystemsSearchCount",
    iconComponent: Icon,
    iconType: "deployment-unit",
    key: "systems",
    label: "Systems",
    elasticsearchRequired: true,
  },
  domains: {
    component: SearchTabDomains,
    countVar: "filteredDomainsSearchCount",
    iconComponent: Icon,
    iconType: "global",
    key: "domains",
    label: "Domains",
    elasticsearchRequired: true,
  },
  tech_docs: {
    component: SearchTabTechDocs,
    countVar: "filteredDocumentSearchCount",
    iconComponent: Icon,
    iconType: "file-text",
    key: "tech_docs",
    label: "Tech Docs",
    elasticsearchRequired: true,
  },
};

export default {
  components: {
    HeaderCard,
    CounterBadge,
    OpsIcon,
    SearchTabDomains,
    SearchTabSystems,
    SearchTabServices,
    SearchTabTechDocs,
  },

  mixins: [featureFlags],
  inject: ["routes"],

  props: {
    elasticsearchEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      Tabs,
      activeTab: window.location.hash || Tabs.services.key,
      urlPersistence: {
        params: [
          {
            prop: "findTerm",
            as: "findTerm",
          },
        ],
      },
    };
  },

  computed: {
    ...mapState({
      serviceSearchResults: (state) => state.servicesSearch.searchResults,
      filteredServiceSearchCount: (state) => state.servicesSearch.filteredCount,
      searchTermState: (state) => state.servicesSearch.searchTerm,
      filteredDocumentSearchCount: (state) =>
        state.documentsSearch.filteredCount,
      filteredDomainsSearchCount: (state) => state.domainsSearch.filteredCount,
      filteredSystemsSearchCount: (state) => state.systemsSearch.filteredCount,
    }),
    tabsToShow() {
      const tabs = Object.values(this.Tabs).filter((tab) => {
        if (tab.elasticsearchRequired) {
          if (tab.key == "tech_docs") {
            return this.showTechDocsTab;
          } else {
            return this.elasticsearchEnabled;
          }
        }

        return true;
      });

      tabs.forEach((tab) => (tab.count = this[tab.countVar])); // Inject count from mapped state

      return tabs;
    },
    title() {
      if (this.showTabs) {
        return `Search results for "${this.findTerm}"`;
      } else {
        const resultWord = pluralize(
          "result",
          this.serviceSearchResults.length,
        );

        return `${this.filteredServiceSearchCount} search ${resultWord} for "${this.searchTermState}"`;
      }
    },
    showTabs() {
      return this.elasticsearchEnabled;
    },
    showTechDocsTab() {
      return (
        this.hasFeatureFlag("tech_docs_search_ui") &&
        !this.hasFeatureFlag("disable_repositories")
      );
    },
    currentPageScope() {
      if (this.isValidTabHash()) {
        return this.activeTab;
      }

      return "services";
    },
  },

  created() {
    this.$store.registerModuleOnce(
      "servicesSearch",
      search({ itemsPerPage: 20 }),
    );
    this.$store.registerModuleOnce(
      "documentsSearch",
      search({ itemsPerPage: 5 }),
    );
    this.$store.registerModuleOnce(
      "systemsSearch",
      search({ itemsPerPage: 20 }),
    );
    this.$store.registerModuleOnce(
      "domainsSearch",
      search({ itemsPerPage: 20 }),
    );
    this.$store.registerModuleOnce("levels", levels); // must register the levels module for ServicesTable
    this.fetchLevels();

    window.addEventListener("hashchange", this.handleHashChange);
  },

  mounted() {
    let windowLocationHash = window.location.hash;

    if (windowLocationHash) {
      windowLocationHash = windowLocationHash.split("#")[1];
    }

    this.handleTabChange(windowLocationHash || Tabs.services.key);

    // Perform each tab's search so they load and we know the count
    this.$nextTick(() => {
      this.updateServiceTableData({
        queryParams: { searchTerm: this.findTerm },
      });
      this.documentUpdateData({ queryParams: { searchTerm: this.findTerm } });
      this.updateSystemData({ queryParams: { searchTerm: this.findTerm } });
      this.updateDomainData({ queryParams: { searchTerm: this.findTerm } });
    });
  },

  methods: {
    ...mapActions({
      updateServiceTableData: "servicesSearch/updateServiceTableData",
      documentUpdateData: "documentsSearch/documentUpdateData",
      updateSystemData: "systemsSearch/updateSystemData",
      updateDomainData: "domainsSearch/updateDomainData",
      setCurrentSearchTab: "search/setCurrentSearchTab",
      fetchLevels: "levels/fetchLevels",
    }),
    handleNewService() {
      window.location = this.routes.new_service_path();
    },
    handleHashChange(event) {
      const hashFromUrl = new URL(event.newURL).hash;

      if (hashFromUrl) {
        this.handleTabChange(hashFromUrl.split("#")[1]);
      }
    },
    isValidTabHash(key) {
      return Object.keys(Tabs).indexOf(key || this.activeTab) > -1;
    },
    handleTabChange(key) {
      if (this.isValidTabHash(key)) {
        this.activeTab = key;
      } else {
        this.activeTab = Tabs.services.key;
      }

      window.history.replaceState("", "", `#${this.activeTab}`);
      this.setCurrentSearchTab(this.activeTab);
      this.emitTabChanged(this.activeTab);
    },
    emitTabChanged(activeTab) {
      this.$el.dispatchEvent(
        new CustomEvent("menu-sublist-changed", {
          detail: { key: activeTab },
          bubbles: true,
        }),
      );
    },
  },
};
</script>
