<template>
  <a-card>
    <h3 v-if="isEmptyServiceSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".<br />
        To improve your search results, try searching for service names,
        aliases, tags, teams, descriptions, products, languages or frameworks.
      </p>
    </h3>
    <template v-else>
      <h3 style="margin-left: 6px">
        {{ totalTableCount }} {{ pluralize("Service", totalTableCount) }}
      </h3>
      <ServicesTable
        :services="serviceSearchResults"
        :defaultSort="null"
        :pagination="serviceSearchPagination"
        :sortState="sortState"
        :loading="isFetchingServiceSearchResults"
        :visibleColumns="visibleColumns"
        :totalLevelCount="totalLevelCount"
        :showHighlightedContent="true"
        @tableChange="handleTableChange"
      />
    </template>
  </a-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import pluralize from "pluralize";

import ServicesTable from "@/components/ServicesTable.vue";
import { table } from "@/mixins/table.jsx";
import { featureFlags } from "@/mixins/featureFlags.js";

export default {
  components: {
    ServicesTable,
  },

  mixins: [table, featureFlags],

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      visibleColumns: [
        "level_index",
        "alert_status",
        "name",
        ...(this.hasFeatureFlag("service_types") ? ["type"] : []),
        "owner",
        "tier",
        "last_deploy",
        "tags",
        "tools",
      ],
    };
  },

  computed: {
    ...mapState({
      searchTermState: (state) => state.servicesSearch.searchTerm,
      serviceSearchResults: (state) => state.servicesSearch.searchResults,
      isFetchingServiceSearchResults: (state) =>
        state.servicesSearch.isFetchingSearchResults,
      totalTableCount: (state) => state.servicesSearch.filteredCount,
      totalLevelCount: (state) => state.servicesSearch.totalLevelCount,
      errorsFetchingServiceSearchResults: (state) =>
        state.servicesSearch.errorsFetchingSearchResults,
      paginationState: (state) => state.servicesSearch.pagination,
      sortState: (state) => state.servicesSearch.sort,
    }),
    serviceSearchPagination() {
      return this.errorsFetchingServiceSearchResults.length
        ? false
        : this.pagination;
    },
    isEmptyServiceSearchResult() {
      return (
        this.serviceSearchResults.length === 0 &&
        !this.isFetchingServiceSearchResults
      );
    },
    initialSort() {
      // This must be a computed property because we're overriding the table mixing
      return {};
    },
  },

  mounted() {
    this.$store.watch(
      (state) => state.servicesSearch.errorsFetchingSearchResults,
      (errors) => {
        if (errors && errors.length) {
          this.$message.error(errors[0].message, 10);
        }
      },
    );
  },

  methods: {
    ...mapActions({
      updateTableData: "servicesSearch/updateServiceTableData",
    }),
    pluralize,
  },
};
</script>
