import { gql } from "@apollo/client";

export const SERVICE_LEVEL_FRAGMENT = gql`
  fragment ServiceLevel on ServiceStats {
    rubric {
      level {
        index
        name
      }
    }
  }
`;

export const LEVEL_TOTAL_COUNT_FRAGMENT = gql`
  fragment levelTotalCountFragment on Rubric {
    levels {
      totalCount
    }
  }
`;

const CHECK_RESULT_DETAILS_FRAGMENT = gql`
  fragment checkResultDetailsFragment on ServiceCheckResults {
    byLevel {
      nodes {
        level {
          name
          index
        }
        items {
          enabledTotalCount
          enabledFailingCount
          enabledPendingCount
          upcomingTotalCount
          upcomingFailingCount
          upcomingPendingCount
          nodes {
            status
            message
            htmlMessage
            warnMessage
            createdAt
            isInitialManualCheckResult
            check {
              id
              gid: id
              notes: rawNotes
              name
              type
              args
              fix
              url
              ... on ManualCheck {
                updateRequiresComment
                startTime
                prevWindowStart
                windowEnd
              }
              category {
                id
                name
                container {
                  ... on Rubric {
                    href
                  }
                  ... on Scorecard {
                    href
                    affectsOverallServiceLevels
                  }
                }
              }
              enableOn
              owner {
                ... on Team {
                  name
                  href
                  id
                  gid: id
                }
                ... on User {
                  name
                  href
                  id
                  gid: id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SERVICE_SHOW_RUBRIC_QUERY = gql`
  query serviceShowRubric($id: ID!, $categoryId: ID) {
    account {
      service(id: $id) {
        serviceStats {
          scorecards {
            nodes {
              scorecard {
                id
                name
                affectsOverallServiceLevels
              }
              categories {
                edges {
                  level {
                    id
                    name
                  }
                  node {
                    id
                    name
                  }
                }
              }
              checkResults {
                ...checkResultDetailsFragment
              }
            }
          }
          rubric {
            checkResults {
              ...checkResultDetailsFragment
            }
            level {
              index
              name
              description
            }
            categories {
              edges {
                node {
                  name
                  id
                  alias
                }
                level {
                  name
                  id
                  index
                }
              }
            }
            categoryLevel(categoryId: $categoryId) {
              name
              index
              id
            }
          }
        }
        dependencies {
          totalCount
        }
        dependents {
          totalCount
        }
        relatedResources(resourceTypes: [infrastructure_resource]) {
          totalCount
        }
        packages {
          totalCount
        }
        codeIssues {
          totalCount
        }
      }
      rubric {
        ...levelTotalCountFragment
      }
    }
  }
  ${LEVEL_TOTAL_COUNT_FRAGMENT}
  ${CHECK_RESULT_DETAILS_FRAGMENT}
`;

export const SERVICE_LEVELS_QUERY = gql`
  query services($ownerAlias: String, $searchTerm: String) {
    account {
      services(ownerAlias: $ownerAlias, searchTerm: $searchTerm) {
        nodes {
          id
          serviceStats {
            ...ServiceLevel
          }
        }
      }
      rubric {
        ...levelTotalCountFragment
      }
    }
  }
  ${SERVICE_LEVEL_FRAGMENT}
  ${LEVEL_TOTAL_COUNT_FRAGMENT}
`;

export const REPORT_CHARTS_QUERY = gql`
  query servicesReport(
    $filter: [ServiceFilterInput!]
    $filterId: ID
    $includeScorecards: Boolean
  ) {
    account {
      rubric {
        levels {
          totalCount
          nodes {
            index
            name
            alias
          }
        }
        categories {
          nodes {
            id
            name
          }
        }
      }
      servicesReport(filter: $filter, filterId: $filterId) {
        totalServicesNotEvaluated
        levelCounts {
          level {
            name
          }
          serviceCount
        }
        categoryLevelCounts(includeScorecards: $includeScorecards) {
          category {
            name
          }
          level {
            name
            index
          }
          serviceCount
        }
      }
    }
  }
`;

export const RUBRIC_REPORT_SERVICES_QUERY = gql`
  query services(
    $after: String
    $first: Int
    $sortBy: ServiceSortEnum
    $filter: [ServiceFilterInput!]
    $filterId: ID
    $connective: ConnectiveEnum
    $search: String
    $categoryId: ID
    $categoryLevelFilter: CategoryFilterInput
  ) {
    account {
      rubric {
        description
      }
      services(
        after: $after
        first: $first
        sortBy: $sortBy
        filter: $filter
        connective: $connective
        searchTerm: $search
        filterId: $filterId
        category: $categoryLevelFilter
      ) {
        totalCount
        filteredCount
        predicates
        nodes {
          id
          name
          linkable
          href
          locked
          owner {
            name
            href
          }
          tier {
            index
            name
          }
          lifecycle {
            index
            name
          }
          type {
            name
          }
          hasServiceConfigError
          service_level: serviceStats {
            ...ServiceLevel
            rubric {
              categories {
                edges {
                  node {
                    name
                    id
                  }
                  level {
                    name
                    id
                    index
                  }
                }
              }
              categoryLevel(categoryId: $categoryId) {
                name
                index
                id
              }
            }
            scorecards(affectsOverallServiceLevels: true) {
              nodes {
                categories {
                  edges {
                    node {
                      name
                      id
                    }
                    level {
                      name
                      id
                      index
                    }
                  }
                }
              }
            }
          }
          level_index: levelIndex
        }
      }
      rubric {
        levels {
          totalCount
        }
      }
    }
  }
  ${SERVICE_LEVEL_FRAGMENT}
`;

export const RUBRIC_REPORT_SERVICES_BY_TEAM_QUERY = gql`
  query fetchRubricReportByTeam(
    $after: String
    $first: Int
    $ids: [ID!]
    $groupIds: [ID!]
    $filter: [ServiceFilterInput!]
    $filterId: ID
    $connective: ConnectiveEnum
    $categoryLevelFilter: CategoryFilterInput
    $categoryId: ID
    $includeSubteams: Boolean
  ) {
    account {
      teams(
        after: $after
        first: $first
        ids: $ids
        groupIds: $groupIds
        includeSubteams: $includeSubteams
      ) {
        totalCount
        nodes {
          id
          name
          href
          group {
            name
          }
          parentTeam {
            name
            href
          }
          contacts {
            id
            type
            displayName
            address
            targetHref
          }
          services(
            filter: $filter
            connective: $connective
            category: $categoryLevelFilter
            filterId: $filterId
          ) {
            id
            healthReport {
              overallLevel {
                alias
              }
            }
            service_level: serviceStats {
              rubric {
                level {
                  alias
                  id
                  index
                  name
                }
                categoryLevel(categoryId: $categoryId) {
                  name
                  index
                  id
                  alias
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const RUBRIC_REPORT_SERVICE_DETAILS_BY_TEAM_QUERY = gql`
  query teamServices(
    $filter: [ServiceFilterInput!]
    $filterId: ID
    $connective: ConnectiveEnum
    $categoryLevelFilter: CategoryFilterInput
    $categoryId: ID
    $ids: [ID!]
  ) {
    account {
      teams(ids: $ids) {
        nodes {
          id
          services(
            filter: $filter
            connective: $connective
            category: $categoryLevelFilter
            filterId: $filterId
          ) {
            id
            name
            href
            tier {
              index
              name
              description
            }
            type {
              name
            }
            lifecycle {
              name
              index
            }
            healthReport {
              overallLevel {
                alias
              }
            }
            service_level: serviceStats {
              rubric {
                level {
                  alias
                  id
                  index
                  name
                }
                categories {
                  edges {
                    node {
                      name
                      id
                    }
                    level {
                      name
                      id
                      index
                    }
                  }
                }
                categoryLevel(categoryId: $categoryId) {
                  name
                  index
                  id
                  alias
                }
              }
              scorecards(affectsOverallServiceLevels: true) {
                nodes {
                  categories {
                    edges {
                      node {
                        name
                        id
                      }
                      level {
                        name
                        id
                        index
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
